"use client";

import { useEffect } from "react";
import axios from "axios";
import Image from "next/image";
import Error from "next/error";
import Image500 from "@/assets/images/500.png";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  // 前端收集报错信息用于排查错误
  useEffect(() => {
    axios({
      url: `${process.env.NEXT_PUBLIC_API_URL}/api/plugin/crashes`,
      method: "POST",
      data: {
        msg: error,
      },
    });
  }, [error]);

  // TODO: 后端收集报错信息
  // useEffect(() => {
  //   Sentry.captureException(error);
  // }, [error]);

  return (
    <html>
      <body>
        <div className="flex flex-col items-center justify-center m-auto not-found">
          <div className="text-center flex justify-center -mt-20">
            <Image src={Image500.src} width={828} height={300} alt="404" />
          </div>
          <h1 className="text-center font-medium text-lg text-neutral-7 mt-3 mb-6">
            服务器出错了，刷新试试吧
          </h1>
          <button
            style={{ width: "106px" }}
            className="h-8 px-6 py-1 bg-zinc-700 rounded-md border border-zinc-700 justify-center items-center gap-1.5 inline-flex text-white"
            onClick={() => reset()}
          >
            刷新试试
          </button>
        </div>
      </body>
    </html>
  );
}
